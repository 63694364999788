.display-commands-paper {
  border: none;
  width: 40%;
  position: absolute;
  left: 30%;
  top: 25%;
  outline-style: none;
}

.display-commands__command {
  padding: 5px 20px;
}

.display-commands__command.selected {
  background-color: #d2d5d7;
}

.display-commands__command:hover {
  background-color: #d2d5d7;
}
