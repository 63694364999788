/*
        This font software is the property of Commercial Type.

        You may not modify the font software, use it on another website, or install it on a computer.

        License information is available at http://commercialtype.com/eula
        For more information please visit Commercial Type at http://commercialtype.com or email us at info[at]commercialtype.com

        Copyright (C) 2020 Schwartzco Inc.
        License: 2006-JISUXY
*/
@font-face {
  font-family: "Graphik";
  src:
    url("Graphik-Thin-Web.woff2") format("woff2"),
    url("Graphik-Thin-Web.woff") format("woff");
  font-style: normal;
  font-stretch: normal;
  font-weight: 100;
}

@font-face {
  font-family: "Graphik";
  src:
    url("Graphik-Extralight-Web.woff2") format("woff2"),
    url("Graphik-Extralight-Web.woff") format("woff");
  font-style: normal;
  font-stretch: normal;
  font-weight: 200;
}

@font-face {
  font-family: "Graphik";
  src:
    url("Graphik-Light-Web.woff2") format("woff2"),
    url("Graphik-Light-Web.woff") format("woff");
  font-style: normal;
  font-stretch: normal;
  font-weight: 300;
}

@font-face {
  font-family: "Graphik";
  src:
    url("Graphik-Regular-Web.woff2") format("woff2"),
    url("Graphik-Regular-Web.woff") format("woff");
  font-style: normal;
  font-stretch: normal;
  font-weight: 400;
}

@font-face {
  font-family: "Graphik";
  src:
    url("Graphik-Medium-Web.woff2") format("woff2"),
    url("Graphik-Medium-Web.woff") format("woff");
  font-style: normal;
  font-stretch: normal;
  font-weight: 500;
}

@font-face {
  font-family: "Graphik";
  src:
    url("Graphik-Semibold-Web.woff2") format("woff2"),
    url("Graphik-Semibold-Web.woff") format("woff");
  font-style: normal;
  font-stretch: normal;
  font-weight: 600;
}

@font-face {
  font-family: "Graphik";
  src:
    url("Graphik-Bold-Web.woff2") format("woff2"),
    url("Graphik-Bold-Web.woff") format("woff");
  font-style: normal;
  font-stretch: normal;
  font-weight: 700;
}

@font-face {
  font-family: "Graphik";
  src:
    url("Graphik-Black-Web.woff2") format("woff2"),
    url("Graphik-Black-Web.woff") format("woff");
  font-style: normal;
  font-stretch: normal;
  font-weight: 800;
}

@font-face {
  font-family: "Graphik";
  src:
    url("Graphik-Super-Web.woff2") format("woff2"),
    url("Graphik-Super-Web.woff") format("woff");
  font-style: normal;
  font-stretch: normal;
  font-weight: 900;
}
