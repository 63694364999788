.topic-discussion-summary-overview-chart {
  margin-top: 30px;
}

.topic-discussion-summary-overview-chart svg g text {
  text-rendering: geometricPrecision;
  cursor: pointer;
}

.topic-discussion-summary-overview-chart g[aria-label="fy-axis tick label"] {
  font-weight: bold;
  font-size: 16px;
}

.topic-discussion-summary-overview-chart label {
  font-size: 16px;
  font-weight: bold;
}

/* Theme plot */
.topic-discussion-summary-overview-chart__theme-plot g[aria-label="x-axis label"] {
  font-size: 16px;
  transform: translate(-180px, -25px);
}

.topic-discussion-summary-overview-chart__theme-plot g[aria-label="x-axis tick"] g {
  transform: translateY(-8px);
}

.topic-discussion-summary-overview-chart__theme-plot g[aria-label="y-axis tick"] {
  transform: translateX(10px);
}

/* Topics plot */
.topic-discussion-summary-overview-chart__topics-plot g[aria-label="x-axis label"] {
  font-size: 16px;
  transform: translate(-180px, -25px);
}

.topic-discussion-summary-overview-chart__topics-plot g[aria-label="x-axis tick"] g {
  transform: translateY(-8px);
}

.topic-discussion-summary-overview-chart__topics-plot g[aria-label="y-axis tick"] {
  transform: translateX(10px);
}

/* Speakers plot */
.topic-discussion-summary-overview-chart__speakers-plot g[aria-label="y-axis tick"] {
  transform: translateX(10px);
}

.topic-discussion-summary-overview-chart__speakers-plot g[aria-label="x-axis tick"] g {
  transform: translateY(-8px);
}
