.output-templates {
  margin: 10px;
}

.output-options__name {
  padding: 5px 20px;
}

.output-options__name:hover {
  background-color: #d2d5d7;
}

.output-options__name.selected {
  background-color: #d2d5d7;
}
