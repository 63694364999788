:root {
  /* border */
  --astra-color-border1: #cccccc;

  /* background */
  --astra-color-bg1: #f7f8fa;

  /* highlight */
  --astra-color-highlight-yellow: #ff0;
  --astra-color-highlight-red: #a84232;
  --astra-color-highlight-green: #96b347;
  --astra-color-highlight-blue: #1e4d99;
  --astra-color-highlight-wisteria: rgba(126, 85, 151, 0.3);
  --astra-color-highlight-cayenne: rgba(217, 88, 46, 0.3);
  --astra-color-highlight-saffron: rgba(247, 186, 68, 0.3);
  --astra-color-highlight-willow: rgba(170, 204, 107, 0.3);
  --astra-color-highlight-berry: rgba(148, 41, 88, 0.3);
  --astra-color-highlight-orange: #ff9632;

  /* GRAY */
  --astra-color-gray5: #f2f2f2;
  --astra-color-gray7: #f5f5f5;
  --astra-color-gray10: #f7f8fa;
  --astra-color-gray15: #eeeeee;
  --astra-color-gray20: #e8eaeb;
  --astra-color-gray30: #d2d5d7;
  --astra-color-gray40: #bbc0c3;
  --astra-color-gray50: #a5abaf;
  --astra-color-gray60: #8e969b;
  --astra-color-gray70: #778187;
  --astra-color-gray80: #616c72;
  --astra-color-gray90: #4a575e;
  --astra-color-gray100: #34424a;

  /* BLACK */
  --astra-color-black90: #1b1b1b;

  /* BLUE */
  --astra-color-blue10: #e6efff;
  --astra-color-blue15: #2196f314;
  --astra-color-blue20: #b8c9e6;
  --astra-color-blue40: #7a9acc;
  --astra-color-blue60: #4770b3;
  --astra-color-blue80: #1e4d99;
  --astra-color-blue100: #003180;

  /* ORANGE (Generated with the help of Copilot) */
  --astra-color-orange10: #fff2e6;
  --astra-color-orange20: #ffcc99;
  --astra-color-orange40: #ff9933;
  --astra-color-orange60: #ff8000;
  --astra-color-orange80: #e65c00;
  --astra-color-orange100: #cc4400;

  /* PURPLE (Generated with the help of Copilot) */
  --astra-color-purple10: #f2e6ff;
  --astra-color-purple20: #cc99ff;
  --astra-color-purple40: #9933ff;
  --astra-color-purple60: #8000ff;
  --astra-color-purple80: #5c00e6;
  --astra-color-purple100: #4400cc;

  /* RED (Generated with the help of Copilot) */
  --astra-color-red10: #f9eaea;
  --astra-color-red20: #e9d1ce;
  --astra-color-red40: #d1a3a0;
  --astra-color-red60: #bf6c60;
  --astra-color-red80: #a84232;
  --astra-color-red100: #8f1e0a;

  /* GREEN (Generated with the help of Copilot) */
  --astra-color-green10: #f0f7e6;
  --astra-color-green20: #d4e8a3;
  --astra-color-green40: #96b347;
  --astra-color-green60: #b4ca79;
  --astra-color-green80: #7daa3d;
  --astra-color-green100: #4d7e0f;

  /* SAFFRON (Generated with the help of Copilot) */
  --astra-color-saffron10: #fff7e6;
  --astra-color-saffron20: #ffebcc;
  --astra-color-saffron40: #f7ba44;
  --astra-color-saffron60: #f7c96b;
  --astra-color-saffron80: #d19e3a;
  --astra-color-saffron100: #b37f0f;

  /* WILLOW (Generated with the help of Copilot) */
  --astra-color-willow10: #f0f7e6;
  --astra-color-willow20: #d4e8a3;
  --astra-color-willow40: #aacc6b;
  --astra-color-willow60: #b4ca79;
  --astra-color-willow80: #8aa756;
  --astra-color-willow100: #5f8d2b;

  /* SKY (Generated with the help of Copilot) */
  --astra-color-sky10: #e6f7ff;
  --astra-color-sky20: #99d6ff;
  --astra-color-sky40: #00b1cc;
  --astra-color-sky60: #50ada4;
  --astra-color-sky80: #3e8780;
  --astra-color-sky100: #266346;

  /* MISC */
  --astra-color-sky: #00b1cc;
  --astra-color-sky-hover: #0090a6;
  --astra-color-pacific: #006c87;
  --astra-color-pacific-hover: #004e61;
  --astra-color-berry: #942958;
  --astra-color-berry-hover: #881c4b;
  --astra-color-saffron: #f7ba44;
  --astra-color-saffron-hover: #d19e3a;
  --astra-color-cayenne: #d9582e;
  --astra-color-cayenne-hover: #b34926;
  --astra-color-patina: #50ada4;
  --astra-color-patina-hover: #3e8780;
  --astra-color-jade: #3d746f;
  --astra-color-jade-hover: #2b5854;
  --astra-color-midnight: #1d2d36;
  --astra-color-midnight-hover: #0c0e0e;
  --astra-color-coral: #cb3b43;
  --astra-color-coral-hover: #b32b33;
  --astra-color-willow: #aacc6b;
  --astra-color-willow-hover: #8aa756;
  --astra-color-pine: #3c9169;
  --astra-color-pine-hover: #266346;
  --astra-color-wisteria: #7e5597;
  --astra-color-wisteria-hover: #644179;
  --astra-color-heather: #d293be;
  --astra-color-heather-hover: #a0638d;
}

/*
    CSS classes for color
*/
.astra-color-sky {
  color: var(--astra-color-sky);
}

.astra-color-sky:hover {
  color: var(--astra-color-sky-hover);
}

.astra-bg-sky {
  background-color: var(--astra-color-sky);
}

.astra-bg-sky:hover {
  background-color: var(--astra-color-sky-hover);
}

.astra-color-pacific {
  color: var(--astra-color-pacific);
}

.astra-color-pacific:hover {
  color: var(--astra-color-pacific-hover);
}

.astra-bg-pacific {
  background-color: var(--astra-color-pacific);
}

.astra-bg-pacific:hover {
  background-color: var(--astra-color-pacific-hover);
}

.astra-color-berry {
  color: var(--astra-color-berry);
}

.astra-color-berry:hover {
  color: var(--astra-color-berry-hover);
}

.astra-bg-berry {
  background-color: var(--astra-color-berry);
}

.astra-bg-berry:hover {
  background-color: var(--astra-color-berry-hover);
}

.astra-color-saffron {
  color: var(--astra-color-saffron);
}

.astra-color-saffron:hover {
  color: var(--astra-color-saffron-hover);
}

.astra-bg-saffron {
  background-color: var(--astra-color-saffron);
}

.astra-bg-saffron:hover {
  background-color: var(--astra-color-saffron-hover);
}

.astra-color-cayenne {
  color: var(--astra-color-cayenne);
}

.astra-color-cayenne:hover {
  color: var(--astra-color-cayenne-hover);
}

.astra-bg-cayenne {
  background-color: var(--astra-color-cayenne);
}

.astra-bg-cayenne:hover {
  background-color: var(--astra-color-cayenne-hover);
}

.astra-color-patina {
  color: var(--astra-color-patina);
}

.astra-color-patina:hover {
  color: var(--astra-color-patina-hover);
}

.astra-bg-patina {
  background-color: var(--astra-color-patina);
}

.astra-bg-patina:hover {
  background-color: var(--astra-color-patina-hover);
}

.astra-color-jade {
  color: var(--astra-color-jade);
}

.astra-color-jade:hover {
  color: var(--astra-color-jade-hover);
}

.astra-bg-jade {
  background-color: var(--astra-color-jade);
}

.astra-bg-jade:hover {
  background-color: var(--astra-color-jade-hover);
}

.astra-color-midnight {
  color: var(--astra-color-midnight);
}

.astra-color-midnight:hover {
  color: var(--astra-color-midnight-hover);
}

.astra-bg-midnight {
  background-color: var(--astra-color-midnight);
}

.astra-bg-midnight:hover {
  background-color: var(--astra-color-midnight-hover);
}

.astra-color-coral {
  color: var(--astra-color-coral);
}

.astra-color-coral:hover {
  color: var(--astra-color-coral-hover);
}

.astra-bg-coral {
  background-color: var(--astra-color-coral);
}

.astra-bg-coral:hover {
  background-color: var(--astra-color-coral-hover);
}

.astra-color-willow {
  color: var(--astra-color-willow);
}

.astra-color-willow:hover {
  color: var(--astra-color-willow-hover);
}

.astra-bg-willow {
  background-color: var(--astra-color-willow);
}

.astra-bg-willow:hover {
  background-color: var(--astra-color-willow-hover);
}

.astra-color-pine {
  color: var(--astra-color-pine);
}

.astra-color-pine:hover {
  color: var(--astra-color-pine-hover);
}

.astra-bg-pine {
  background-color: var(--astra-color-pine);
}

.astra-bg-pine:hover {
  background-color: var(--astra-color-pine-hover);
}

.astra-color-wisteria {
  color: var(--astra-color-wisteria);
}

.astra-color-wisteria:hover {
  color: var(--astra-color-wisteria-hover);
}

.astra-bg-wisteria {
  background-color: var(--astra-color-wisteria);
}

.astra-bg-wisteria:hover {
  background-color: var(--astra-color-wisteria-hover);
}

.astra-color-heather {
  color: var(--astra-color-heather);
}

.astra-color-heather:hover {
  color: var(--astra-color-heather-hover);
}

.astra-bg-heather {
  background-color: var(--astra-color-heather);
}

.astra-bg-heather:hover {
  background-color: var(--astra-color-heather-hover);
}

.highlight-wisteria {
  background-color: rgba(126, 85, 151, 0.3);
}

.highlight-cayenne {
  background-color: rgba(217, 88, 46, 0.3);
}

.highlight-saffron {
  background-color: rgba(247, 186, 68, 0.3);
}

.highlight-willow {
  background-color: rgba(170, 204, 107, 0.3);
}

.highlight-berry {
  background-color: rgba(148, 41, 88, 0.3);
}
