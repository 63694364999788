.topic-discussion-summary-master-details-datagrid__cell {
  padding: 10px !important;
}

.topic-discussion-summary-master-details-datagrid__cell,
.topic-discussion-summary-master-details-datagrid__panel-content * {
  pointer-events: all;
  user-select: text;
  cursor: auto;
}
