.bar-chart svg g text {
  text-rendering: geometricPrecision;
}

.bar-chart text {
  font-size: 12px;
  color: var(--astra-color-midnight);
}

.bar-chart g[aria-label="bar"] {
  cursor: pointer;
}
