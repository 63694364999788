.editor-document {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.editor-document .astra.document {
  background-color: #fff;
  box-shadow: 0 1px 4px rgb(0 0 0 / 13%);
  height: auto !important;
  margin-bottom: 20px;
}

.astra.document .highlight-orange {
  background-color: var(--astra-color-highlight-orange);
}

.ProseMirror:focus {
  outline: none;
}

/* https://app.asana.com/0/0/1203955553710473/1203986077702299/f */
.ProseMirror {
  white-space: normal !important;
}
