.topic-heatmap__container .topic-heatmap__top .event-date > label {
  color: var(--astra-color-midnight);
  font-size: 30px;
  font-weight: 700;
  margin-right: 20px;
  margin-top: 5px;
}

.topic-heatmap__container .topic-heatmap__top .MuiTextField-root {
  margin-right: 40px;
}
