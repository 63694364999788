.editor-document.with-fixed-menu {
  margin-top: 66px;
}

.astra.document .highlight-block::before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  position: absolute;
  margin-left: -32px;
  margin-top: -4px;
  width: 28px;
  height: 28px;
  background-image: url("../../../public/icons/icon/arrow-right-solid.svg");
  background-size: contain;
  background-repeat: no-repeat;
  animation-name: removeArrow;
  animation-duration: 2s;
  animation-delay: 1s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;

  /* Used this filter value generator to get the filter value for var(--astra-color-pacific) - https://codepen.io/sosuke/pen/Pjoqqp */
  filter: invert(24%) sepia(96%) saturate(835%) hue-rotate(156deg) brightness(107%) contrast(101%);
}

.astra.document .title.highlight-block::before {
  margin-top: 8px;
}

.astra.document .heading-1.highlight-block::before {
  margin-top: 2px;
}

.astra.document .heading-2.highlight-block::before {
  margin-top: 0px;
}

.astra.document .heading-3.highlight-block::before {
  margin-top: -2px;
}

.astra.document .heading-4.highlight-block::before {
  margin-top: -3px;
}

.astra.document .heading-5.highlight-block::before {
  margin-top: -5px;
}

.astra.document .heading-6.highlight-block::before {
  margin-top: -6px;
}

@keyframes removeArrow {
  0% {
    display: inline-block;
    opacity: 1;
  }

  33% {
    opacity: 0;
  }

  66% {
    opacity: 1;
  }

  100% {
    display: none;
    opacity: 0;
  }
}
