:root {
  --bistro-mention-color: var(--astra-color-blue10);
  --bistro-invalid-mention-color: var(--astra-color-highlight-yellow);
  --bistro-private-mention-color: var(--astra-color-red10);
  --bistro-invalid-private-mention-color: #ffea00;
}

.chat .chat-message {
  font-size: 11pt;
}

.chat .chat-message p {
  margin-top: 0;
  margin-bottom: 8px;
  line-height: 1.38;
  font-size: 11pt;
  font-family: Arial;
  color: #1d2d36;
  min-height: 16px;
}

.chat .chat-message a {
  color: #1e4d99 !important;
}

.chat .chat-message a:not([href]) {
  color: unset !important;
}

.chat .chat-message b,
.chat .chat-message strong {
  font-family: Arial;
}

.chat .chat-message ol,
.chat .chat-message ul {
  margin: 0;
  padding: 0;
  padding-left: 16px;
}

.chat .chat-message li {
  margin-bottom: 0;
  color: #1d2d36;
  font-size: 11pt;
  font-family: Arial;
}

/* chat message mention */
.chat .chat-message .mention {
  background-color: var(--bistro-mention-color);
}

.chat .astra.document .mention {
  background-color: var(--bistro-mention-color);
}

/* chat message invalid mention */
.chat .chat-message .unknown-user-mention {
  border-radius: 4px;
  border: dashed 1px var(--astra-color-blue60);
  display: inline-block;
  padding: 2px;
  position: relative;
}

.chat .chat-message .unknown-user-mention::after {
  content: "!";
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  background-color: var(--bistro-invalid-private-mention-color);
  border-radius: 50%;
  font-size: 12px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
}

.chat .astra.document .unknown-user-mention {
  border-radius: 4px;
  border: dashed 1px var(--astra-color-blue60);
  display: inline-block;
  padding: 2px;
  position: relative;
}

.chat .astra.document .unknown-user-mention::after {
  content: "!";
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  background-color: var(--bistro-invalid-private-mention-color);
  border-radius: 50%;
  font-size: 12px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
}

/* chat message private mention */
.chat .chat-message .private-mention {
  background-color: var(--bistro-private-mention-color);
}

.chat .astra.document .private-mention {
  background-color: var(--bistro-private-mention-color);
}

/* chat message invalid private mention */
.chat .chat-message .unknown-private-user-mention {
  border-radius: 4px;
  border: dashed 1px var(--bistro-invalid-private-mention-color);
  display: inline-block;
  padding: 2px;
  position: relative;
}

.chat .chat-message .unknown-private-user-mention::after {
  content: "!";
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  background-color: var(--bistro-invalid-private-mention-color);
  border-radius: 50%;
  font-family: Arial;
  font-size: 12px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
}

.chat .astra.document .unknown-private-user-mention {
  background-color: var(--bistro-invalid-private-mention-color);
}

.chat .astra.document .unknown-private-user-mention {
  content: "!";
}

/* chat message invalid user */
.chat .chat-message .missing-user {
  border-radius: 4px;
  border: dashed 1px var(--astra-color-blue60);
  display: inline-block;
  padding: 2px;
  position: relative;
}

.chat .chat-message .missing-user::after {
  content: "?";
  color: #fff;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  background-color: var(--astra-color-blue60);
  border-radius: 50%;
  font-family: Arial;
  font-size: 12px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
}

body.left-sidebar-collapsed .tlui-menu-zone {
  margin-left: 64px;
}
