.ellipsis-anim span {
  opacity: 0;
  animation: ellipsis-dot 1s infinite;
}

.ellipsis-anim span:nth-child(1) {
  animation-delay: 0s;
}

.ellipsis-anim span:nth-child(2) {
  animation-delay: 0.2s;
}

.ellipsis-anim span:nth-child(3) {
  animation-delay: 0.3s;
}

@keyframes ellipsis-dot {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
