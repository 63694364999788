.histogram-chart-shape-label {
  margin: 10px;
}

.histogram-chart-shape-label label {
  font-family: "Graphik";
  font-size: 14pt;
  font-weight: 600;
}

.histogram-chart-shape.no-events rect[cursor="crosshair"] {
  pointer-events: none;
}
