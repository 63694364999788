.datagrid-shape-results .datagrid-shape-results__row .datagrid-shape-results__cell {
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
}

.datagrid-shape-results .datagrid-shape-results__row .MuiDataGrid-cellCheckbox {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0;
}

.datagrid-shape-results__cell.highlight-yellow {
  background-color: var(--astra-color-highlight-yellow);
}

.datagrid-shape-results__cell.highlight-orange {
  background-color: var(--astra-color-highlight-orange);
}

.MuiDataGrid-detailPanel .highlight-yellow {
  background-color: var(--astra-color-highlight-yellow);
}

.MuiDataGrid-detailPanel .highlight-orange {
  background-color: var(--astra-color-highlight-orange);
}
