.group-bubble-chart svg {
  cursor: pointer;
}

.group-bubble-chart svg g text {
  color: var(--astra-color-midnight);
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  text-rendering: geometricPrecision;
}

.group-bubble-chart .details {
  position: relative;
  cursor: pointer;
}

.group-bubble-chart .details .summary {
  list-style: none;
}

.group-bubble-chart .details .summary svg {
  background-color: transparent;
}

.group-bubble-chart .summary::before {
  font-family: "Material Icons";
  content: "chevron_right";
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 34px;
  height: 1.5em;
  position: absolute;
  top: 20px;
}

.group-bubble-chart .summary g text {
  font-size: 16px;
  font-weight: bold;
  color: var(--astra-color-midnight);
}

.group-bubble-chart .details text {
  font-size: 16px;
  font-weight: unset;
  color: var(--astra-color-midnight);
}

.group-bubble-chart .details[open] .summary:before {
  font-family: "Material Icons";
  content: "expand_more";
}

.group-bubble-chart g[aria-label="x-axis tick label"] text {
  font-size: 16px;
  font-weight: bold;
  color: var(--astra-color-midnight);
  cursor: pointer;
}

.group-bubble-chart g[aria-label="y-axis tick label"] {
  cursor: pointer;
}

.group-bubble-chart__bar-chart-plot g[aria-label="y-axis label"] {
  position: absolute;
  transform: translate(-145px, 215px);
}

.group-bubble-chart__bar-chart-plot g[aria-label="y-axis label"] text {
  font-size: 25px;
}
