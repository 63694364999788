.theme-discussion-analysis-chart svg g text {
  text-rendering: geometricPrecision;
  cursor: pointer;
}

.theme-discussion-analysis-chart g[aria-label="y-axis tick label"] {
  font-weight: bold;
}

.theme-discussion-analysis-chart__legend g[aria-label="x-axis label"] {
  font-size: 16px;
  font-weight: bold;
}

.theme-discussion-analysis-chart__legend g[aria-label="x-axis tick label"] {
  transform: translate(45px, 0px);
  font-size: 14px;
  font-weight: bold;
}

.theme-discussion-analysis-chart__return-to-summary {
  cursor: pointer;
  color: var(--astra-color-blue80);
}
