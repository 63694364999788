.search-results-paper {
  width: 40%;
  position: absolute;
  left: 30%;
  top: 25%;
  height: 50%;
}

.search-results-dialog {
  height: 90%;
  overflow: scroll;
}

.search-results-dialog__actions {
  margin-top: 10px;
}

.search-results-dialog__row {
  display: flex;
  margin: 25px 10px;
  text-transform: capitalize;
}

.search-results-dialog__details .title {
  margin: 5px;
  font-weight: bold;
}

.search-results-dialog__details .excerpt {
  margin: 5px;
}

.search-results-dialog__details .source {
  margin: 5px;
  color: #a5abaf;
}

.search-results-dialog .search-results-dialog__accordion {
  background-color: rgba(0, 0, 0, 0.03);
}
