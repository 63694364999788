.data-grid-column-formatting__html-string ul {
  margin: unset;
  padding-left: 18px;
}

.data-grid-column-formatting__html-string li {
  user-select: text;
}

.data-grid-column-formatting__bulleted-list {
  margin: unset;
  padding-left: 18px;
}

.data-grid-column-formatting__bulleted-list li {
  user-select: text;
}
