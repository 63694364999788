.react-draggable-transparent-selection .draggable-iframe-cover {
  display: block;
  z-index: 999999;
}

.draggable-iframe-cover {
  position: absolute;
  display: none;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
