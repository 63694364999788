.tldraw {
  /* position: fixed;
  top: 0;
  left: 48px;
  right: 300px;
  bottom: 0; */
  flex: 1;
}

.tldraw__editor {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

/* Hide first divider */
.tlui-menu-zone__controls .tlui-menu-zone__divider:first-of-type {
  display: none;
}

div.tlui-layout__top .tlui-menu-zone__controls .tlui-popover {
  display: none;
}

.tl-selection__fg__outline {
  stroke-dasharray: 5;
}

.tlui-help-menu {
  display: none;
}
